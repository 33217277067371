import React from "react";
import Card from '../components/card'
import Title from '../components/title'
import FinanceIcon from '../images/icons/finance.png'
import HomeImprovementIcon from '../images/icons/home-improvement.png'
import HealthcareIcon from '../images/icons/healthcare.png'
import EducationIcon from '../images/icons/education.png'
import SaasIcon from '../images/icons/saas.png'


const Industries = () => {

    return (
        <div className='s-wrapper s-industries'>
            <div className="g-container">
                <div className="s-industries__wrapper">
                    <div className="g-row">
                        <div className="g-col-xs-12">
                            <Title
                                type='h2'
                                title='Industries We Specialize In'
                                align='center'
                                underline
                            />
                        </div>
                    </div>
                    <div className="g-row s-industries__cards">
                        <div className="g-col-xs-12 g-col-md-4 s-industries__card">
                            <Card
                                icon={FinanceIcon}
                                iconPosition='top'
                                altText='Icon - Industries Section'
                                title='Finance'
                                primaryCopy='(Personal & Business)'
                            />
                        </div>
                        <div className="g-col-xs-12 g-col-md-4 s-industries__card">
                            <Card
                                icon={HomeImprovementIcon}
                                iconPosition='top'
                                altText='Icon - Industries Section'
                                title='Home Improvement'
                            />
                        </div>
                        <div className="g-col-xs-12 g-col-md-4 s-industries__card">
                            <Card
                                icon={HealthcareIcon}
                                iconPosition='top'
                                altText='Icon - Industries Section'
                                title='Healthcare'
                            />
                        </div>
                        <div className="g-col-xs-12 g-col-md-4 s-industries__card">
                            <Card
                                icon={EducationIcon}
                                iconPosition='top'
                                altText='Icon - Industries Section'
                                title='Education'
                            />
                        </div>
                        <div className="g-col-xs-12 g-col-md-4 s-industries__card">
                            <Card
                                icon={SaasIcon}
                                iconPosition='top'
                                altText='Icon - Industries Section'
                                title='Saas'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Industries;