import React, { Component } from "react"
import Title from './title'
import Button from './button'

import FormField from './form-field'

import image from '../images/sso-hero.jpg'

class Hero extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formFieldSelection: ''
        }

        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange(e) {
        this.setState({
            formFieldSelection: e.target.value.replace(/\s/g, '-')
        });
    }

    render() {
        const createClassList = () => {
            let classList = 'c-hero';
            if(this.props.type) classList = classList + ' c-hero--' + this.props.type;
            return classList;
        }

        const inlineStyle = {
            backgroundImage: 'url(' + image + ')',
        };

        const primaryTitleComponent = (this.props.primaryTitle) ? (
            <Title
                type='h1'
                title={this.props.primaryTitle}
                align='center'
                color='light'
            />
        ) : null;

        const subTitleComponent = (this.props.subTitle) ? (
            <Title
                type='h4'
                title={this.props.subTitle}
                align='center'
                color='light'
            />
        ) : null;

        const buttonURL = (this.state.formFieldSelection === '') ? '/questionnaire' : '/questionnaire?' + this.props.formFieldName + '=' + this.state.formFieldSelection;

        const formFieldComponent = (this.props.formFieldCTA) ? (
            <div className='g-row g-center-xs c-hero__form-element'>
                <div className='c-hero__form-field'>
                    <FormField
                        type={this.props.formFieldType}
                        name='heroCTA'
                        defaultValue={''}
                        options={this.props.formFieldOptions}
                        color='dark'
                        weight='bold'
                        onChange={this.handleOnChange}
                    />
                </div>
                <div className='c-hero__form-button'>
                    <Button
                        id="getStartedHome"
                        type='primary'
                        url={buttonURL}
                        label='Get Started'
                        shadow
                        large
                    />
                </div>
            </div>
        ) : null ;
        
        return (
            <div className={createClassList()} style={inlineStyle}>
                <div className='c-hero__wrapper'>
                    <div className='g-container'>
                        <div className='g-row g-center-xs'>
                            <div className='g-col-xs-12 g-col-md-11'>
                                <div className='g-row g-center-xs'>
                                    {primaryTitleComponent}
                                    {subTitleComponent}
                                </div>
                                {formFieldComponent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        // <Hero
        //     type, 
        //     primaryTitle = false, 
        //     subTitle = false, 
        //     formFieldCTA = false, 
        //     formFieldType = false, 
        //     formFieldOptions = false
        // />
    }
}

export default Hero;