import React from "react";

import Button from '../components/button'
import Title from '../components/title'

const CTA = () => {

    return (
        <div className='s-wrapper--t s-cta'>
            <div className="g-container">
                <div className="s-cta__wrapper">
                    <div className="g-row">
                        <div className="g-col-xs-12">
                            <Title
                                type='h2'
                                title='Ready to do this?'
                                align='center'
                                color='light'
                            />
                            <Title
                                type='h4'
                                title="Let's get to work"
                                weight='regular'
                                align='center'
                                color='light'
                            />
                        </div>
                    </div>
                    <div className="g-row g-center-xs s-cta__button">
                        <Button
                            type='primary'
                            url='mailto:ryan@sunshine-offers.com'
                            label='Contact Us'
                            shadow
                            large
                            externalURL
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CTA;