import React from "react";

import Title from '../components/title'

const Card = ({ icon, iconPosition = 'top', altText = 'icon', title, primaryCopy = false , secondaryCopy = false}) => {

    const createClassList = () => {
        let classList = 'c-card' + ' c-card--' + iconPosition;
        // if(iconPosition) classList = classList + ' c-card--' + iconPosition;
        return classList;
    }

    const titleComponent = (title) ? (
		<Title
            type={ (iconPosition === 'top') ? 'h5' : 'h4' }
            title={title}
            align={ (iconPosition === 'top') ? 'center' : 'left' }
            color='dark'
          />
    ) : null;

    const primaryCopyElement = (primaryCopy) ? (
        <p className='c-card__copy c-card__copy--primary'>{primaryCopy}</p>
    ) : null;
    const secondaryCopyElement = (secondaryCopy) ? (
        <p className='c-card__copy c-card__copy--secondary'>{secondaryCopy}</p>
    ) : null;

    return (
        <div className={createClassList()}>

            {/* g-row g-middle-xs g-start-xs g-center-md g-start-lg */}
            <div className={(iconPosition === 'top') ? 'g-col g-center-xs' : 'g-row g-middle-xs g-center-xs'}>

                {/* c-card__icon g-col-md-12 g-col-lg-4 */}
                <div className={(iconPosition === 'top') ? 'c-card__icon' : 'c-card__icon'}>
                    <img src={icon} alt={altText} />
                </div>
                {/*  g-col-xs g-col-md-12 g-col-lg-8 */}
                <div className={(iconPosition === 'top') ? 'c-card__content' : 'c-card__content'}>
                    {titleComponent}
                    {primaryCopyElement}
                    {secondaryCopyElement}
                </div>
            </div>
        </div>
    );

    // <Card
    //     icon=''
    //     iconPosition='top || left'
    //     altText=''
    //     title=''
    //     copy=''
    // />
}

export default Card;