import React from "react";

const FormField = (
    { 
        type = false,
        name = false,
        defaultValue = '',
        options = false,
        color = false,
        required = false,
        weight = false, 
        onChange = undefined,
        // label = false,
        // value = false,
        // regEx = false,
        // component = false,
    }
) => {

    const createClassList = () => {
        let classList = 'c-form-field';
        if(type) classList = classList + ' c-form-field--' + type;
        if(color) classList = classList + ' c-form-field--color-' + color;
        if(weight) classList = classList + ' c-form-field--weight-' + weight;
        return classList;
    }

    const renderOptions = () => {
        return options.map((option, i) => {
            return <option key={i} value={option.value} disabled={option.disabled}>{option.text}</option>
        })
    }

    const formFieldElement = () => {
        switch(type) {
            case 'dropdown':
                return (
                    <select name={name} defaultValue={defaultValue} required={required} onChange={onChange}>
                        {renderOptions()}
                    </select>
                )
            default:
                return (
                    <input></input>
                )
        }
    }
    
    return (
        <div className={createClassList()}>
            {formFieldElement()}
        </div>
    );

    // <FormField
    //     type='dropdown'
    //     name=''
    //     defaultValue=''
    //     options={[
    //         {
    //             value: '',
    //             text: '',
    //             disabled: true || false
    //         },
    //     ]}
    //     required
    // />
}

export default FormField;