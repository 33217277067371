import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from '../components/hero'
import KeyFeatures from '../sections/key-features'
import Industries from '../sections/industries'
import CTA from '../sections/cta'
import DuoContent from '../components/duo-content'
import DuoImage from '../images/sso-duo.jpg'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero
      primaryTitle='Sunshine Offers'
      subTitle='A fully transparent Native Advertising agency that&nbsp;specializes&nbsp;in&nbsp;performance&nbsp;marketing'
      type='main'
    />
    <DuoContent
        image={DuoImage}
        title='What We Offer:'
        // listItems={[
        //     '<span class="u-bold">State certified Public Adjusters:</span> We have a network of certified public adjusters that are on YOUR team waiting to give you the proper inspection you deserve. Don’t wait for insurance adjusters who are known for trying to submit the least amount possible on your claim',
        //     '<span class="u-bold">Maximize your settlement:</span> Increase your claim by up to 747%',
        //     '<span class="u-bold">Stress Free:</span> Your public adjuster will handle all the paperwork and communication on your behalf with your insurance company.'
        // ]}
        listItems={[
            {
                heading: 'Media Buying',
                item: 'We will set up and run your Native campaigns under your own account so you can have full transparency. We have managed millions of dollars on platforms like Revcontent, Taboola, Oubrain, and more.'
            },
            {
                heading: 'Content Creation',
                item: 'Our team of copywriters will write your direct copy to ensure the highest level of engagement.',
            },
            {
                heading: 'Design',
                item: 'We will build customized landing pages and ad creatives to maximize conversion rate.'
            },
            {
                heading: 'Product Development',
                item: 'Need a funnel? No problem. Our team of developers will build a high converting funnel to maximize your CPA.'
            },
        ]}
    />
    <Industries />
    <CTA />
  </Layout>
)

export default IndexPage
