import React from "react"

import Title from './title'

const DuoContent = ({ image, title, listItems }) => {

    const createClassList = () => {
        let classList = 's-wrapper c-duo-content';
        // if(component) classList = classList + ' c-duo-content--' + component;
        return classList;
    }

    const inlineStyle = {
        backgroundImage: 'url(' + image + ')',
    };

    const titleComponent = (
        <Title 
          title={title}
          type='h3'
          align='left'
          color='dark'
        />
    );

    const renderListItems = () => {
        return listItems.map((data, i) => {
            return (
                <div key={i} className="c-duo-content__list-item-wrapper">
                    <Title 
                        title={data.heading}
                        type='h5'
                        align='left'
                        color='dark'
                    />
                    <ul>
                        <li dangerouslySetInnerHTML={{ __html: data.item }}></li>
                    </ul>
                </div>
            );
        });
    }

    const renderListItemsTwo = () => {
        return listItems.map((data, i) => {
            return (
                <div key={i} className="c-duo-content__list-item-wrapper">
                    <ul>
                        <li>
                            <Title 
                                title={data.heading}
                                type='h5'
                                align='left'
                                color='dark'
                            />
                        </li>
                        <li className='inner' dangerouslySetInnerHTML={{ __html: data.item }}></li>
                    </ul>
                </div>
            );
        });
    }
    
    return (
        <div className={createClassList()}>
            <div className="g-row">
                <div className="g-col-xs-12 g-last-xs g-col-md-6 g-first-md c-duo-content__image" style={inlineStyle}></div>
                <div className="g-col-xs-12 g-col-md-6 c-duo-content__content">
                    <div className="g-row c-duo-content__content-wrapper">
                        <div className="content-block">
                            {titleComponent}
                            {renderListItemsTwo()}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        // <DuoContent
        //     image='https://via.placeholder.com/705x539'
        //     title='Ut enim ad minim veniam quis nostrud ullamco laboris!'
        //     listItems={[
        //         'Lorem ipsum dolor sit amet consectetur',
        //         'Lorem ipsum dolor',
        //         'Lorem ipsum dolor sit amet',
        //         'Lorem ipsum dolor sit amet consectetur al',
        //         'Lorem ipsum dolor sit amet, consectetur'
        //     ]}
        // />
    );
}

export default DuoContent;